<template>
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Entries</label>
          <v-select
            v-model="pagination.perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="pagination.perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            variant="primary"
            @click="
              $router.push({
                name: 'currency-form',
                params: {
                  c_id: 'new',
                },
              })
            "
          >
            Add Currency
          </b-button>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refTagList"
      :items="currency_list"
      responsive
      :fields="tableColumns"
      :per-page="pagination.perPage"
      :current-page="pagination.currentPage"
      primary-key="c_id"
      show-empty
      empty-text="No matching records found"
      class="position-relative"
    >
      <template #cell(index)="data">
        {{ currency_list.indexOf(data.item) + 1 }}
      </template>
      <!-- Column: Actions -->
      <template #cell(edit)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`c-row-${data.item.c_id}-edit-icon`"
            icon="EditIcon"
            size="16"
            class="mx-1"
            @click="
              $router.push({
                name: 'currency-form',
                params: {
                  c_id: data.item.c_id,
                },
              })
            "
          />
          <b-tooltip
            title="Edit Currency"
            :target="`c-row-${data.item.c_id}-edit-icon`"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-start
          "
        >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-end
          "
        >
          <b-pagination
            v-model="pagination.currentPage"
            :total-rows="totalCurrency"
            :per-page="pagination.perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import MasterServices from "@/apiServices/MasterServices";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
  },
  computed: {
    totalCurrency() {
      return this.currency_list.length;
    },
  },
  data() {
    return {
      tableColumns: [
        { key: "index", label: "#", sortable: false },
        { key: "c_name", label: "Name", sortable: false },
        { key: "c_code", label: "Code", sortable: false },
        { key: "c_symbol", label: "Symbol", sortable: false },
        { key: "edit", label: "Edit", sortable: false },
      ],
      currency_list: [],
      pagination: {
        currentPage: 1,
        perPage: 10,
        perPageOptions: [5, 10, 20, 30],
      },
      filters: {
        search: null,
      },
    };
  },
  methods: {
    async getCurrencyList(tag_type_name) {
      try {
        const response = await MasterServices.getAllCurrency();
        if (response.data.status) {
          this.currency_list = response.data.data;
        }
      } catch (err) {
        console.log("Error in getting all currency ", err);
      }
    },
  },
  beforeMount() {
    const { c_id } = this.$route.params;
    if (isNaN(c_id)) {
      this.getCurrencyList(c_id);
    }
  },
};
</script>

<style>
</style>